import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    loginStart: (state: any) => {
      state.isFetching = true;
    },
    loginSuccess: (state: any, action: any) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.error = false;
    },
    loginFailure: (state: any) => {
      state.isFetching = false;
      state.error = true;
    },
    logout: (state: any) => {
      localStorage.removeItem("persist:root");
      state.currentUser = null;
    },
    updateUser: (state: any, action: any) => {
      state.currentUser.data = action.payload;
      console.log("redux updateUser action ==> ", action);
    },
    updateUserEmail: (state: any, action: any) => {
      state.currentUser.data.email = action.payload;
    },
    updateUserAddress: (state: any, action: any) => {
      state.currentUser.data.address = action.payload;
    },
    updateKYCStatus: (state: any, action: any) => {
      state.currentUser.data.verified = action.payload;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  updateUser,
  updateUserEmail,
  updateUserAddress,
  updateKYCStatus,
} = userSlice.actions;
export default userSlice.reducer;
