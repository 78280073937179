import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RippleLoader from "./images/Ripple.svg";
import Home from "./pages/Home/Home";
import { RouteName } from "./constant/routeNames";
import { Helmet } from "react-helmet-async";
import { useMemo } from "react";
import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { getPhantomWallet } from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";

import { DEFAULT_TIMEOUT } from "./connection";
import { role } from "./constant/roleNames";
import Chatbox from "./components/Chatbox";
import { HelmetProvider } from "react-helmet-async";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PayPalScriptOptions } from "@paypal/paypal-js/types/script-options";

require("dotenv").config();

const LazyNavbar = React.lazy(() => import("./components/shared/Navbar"));
const LazyLogin = React.lazy(() => import("./pages/login/Login"));
const LazyLoyalties = React.lazy(() => import("./pages/Launchpad/Loyalties"));
const LazyLoyaltiesRoom = React.lazy(
  () => import("./pages/Launchpad/LoyaltiesSubscribe")
);
const LazyLoyaltiesSubscribe = React.lazy(
  () => import("./pages/Launchpad/LoyaltiesSubscribe")
);
const LazyMediaOfferings = React.lazy(
  () => import("./pages/Home/MediaOfferings")
);
const LazyCreateAsset = React.lazy(() => import("./pages/User/CreateAsset"));
const LazyUpdateAsset = React.lazy(() => import("./pages/User/UpdateAsset"));
const LazyAdminNavbar = React.lazy(
  () => import("./pages/Admin/shared/AdminNavbar")
);

const LazySAdminNavbar = React.lazy(
  () => import("./pages/Seller/shared/AdminNavbar")
);
const LazyDashboard = React.lazy(() => import("./pages/Admin/Dashboard"));
const LazyMinting = React.lazy(() => import("./pages/Admin/Minting"));
const LazyListing = React.lazy(() => import("./pages/Admin/Listing"));
const LazyUsers = React.lazy(() => import("./pages/Admin/Users"));
const LazyAnalytics = React.lazy(() => import("./pages/Admin/Analytics"));
const LazyLaunchpad = React.lazy(() => import("./pages/Admin/Launchpad"));

const LazySDashboard = React.lazy(() => import("./pages/Seller/Dashboard"));

const LazyExploreAlbumTrack = React.lazy(
  () => import("./pages/Home/ExploreAlbumTrack")
);
const LazyCart = React.lazy(() => import("./pages/Home/Cart"));
const LazyProfile = React.lazy(() => import("./pages/User/ProfilePage"));
const LazyEditProfilePage = React.lazy(
  () => import("./pages/User/EditProfilePage")
);
// const LazySettings = React.lazy(() => import("./pages/User/Settings"));
const LazyThankYou = React.lazy(() => import("./pages/User/ThankYou"));
const LazyRegister = React.lazy(() => import("./pages/login/Register"));
const LazyGetPaypalToken = React.lazy(
  () => import("./pages/login/GetPaypalToken")
);
const LazyUnauthorized = React.lazy(() => import("./components/Unauthorized"));
const LazyEmailVerify = React.lazy(() => import("./pages/EmailVerify"));
const LazyResendEmail = React.lazy(() => import("./pages/login/ResendEmail"));
const LazyForgotPassword = React.lazy(
  () => import("./pages/login/ForgotPassword")
);
const LazyResetPassword = React.lazy(
  () => import("./pages/login/ResetPassword")
);
const LazyRegisterSuccessPage = React.lazy(
  () => import("./pages/RegisterSuccessPage")
);
const LazyPaymentSuccessPage = React.lazy(
  () => import("./pages/PaymentSuccessPage")
);
const LazyPaymentFailedPage = React.lazy(
  () => import("./pages/PaymentFailedPage")
);
const LazyAssumptions = React.lazy(
  () => import("./pages/Admin/components/Assumptions")
);
const LazyEditAssumptions = React.lazy(
  () => import("./pages/Admin/components/EditAssumptions")
);
const LazyCookie = React.lazy(() => import("./pages/Policies/cookie"));
const LazyPrivacy = React.lazy(() => import("./pages/Policies/privacy"));
const LazyAccessibility = React.lazy(
  () => import("./pages/Policies/accessibility")
);
const LazyTerms = React.lazy(() => import("./pages/Policies/terms"));
const LazyRequireAuth = React.lazy(() => import("./components/RequireAuth"));
const LazyLanding = React.lazy(() => import("./pages/Landing/Landing"));
const LazyCampaign = React.lazy(() => import("./pages/Landing/Campaign"));
const LazyPress = React.lazy(() => import("./pages/Landing/Press"));
const LazyHelp = React.lazy(() => import("./pages/Landing/Help"));

const LazyCampaignCopy = React.lazy(
  () => import("./pages/Landing/CampaignCopy")
);
const LazyBluredNavbar = React.lazy(
  () => import("./components/shared/BluredNavbar")
);
const LazyLandingFooter = React.lazy(
  () => import("./components/shared/LandingFooter")
);
const LazyMarketPlace = React.lazy(
  () => import("./pages/Marketplace/MarketPlace")
);
const LazyPageNotFound = React.lazy(() => import("./pages/PageNotFound"));
const LazyIndexPool = React.lazy(() => import("./pages/Marketplace/IndexPool"));
const LazyIndexPoolBeta = React.lazy(
  () => import("./pages/Marketplace/IndexPoolBeta")
);
const LazyChatbox = React.lazy(() => import("./components/Chatbox"));
const LazyTv = React.lazy(() => import("./pages/Landing/Tv"));

const network = (process.env.REACT_APP_SOLANA_NETWORK ??
  "devnet") as WalletAdapterNetwork;
const rpcHost =
  process.env.REACT_APP_SOLANA_RPC_HOST ?? anchor.web3.clusterApiUrl("devnet");
const connection = new anchor.web3.Connection(rpcHost);

function App() {
  const endpoint = useMemo(() => clusterApiUrl(network), []);
  const [candyMcId, setCandyMcId] = useState<String>("");

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === "production") {
      console.log = function () {};
      console.info = function () {};
    }
  }, []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      // getSolflareWallet(),
      // getSlopeWallet(),
      // getSolletWallet({ network }),
      // getSolletExtensionWallet({ network }),
    ],
    []
  );

  const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
    //console.log("candyMcId Inside Public Key ", candyMcId);

    if (candyMcId !== "") {
      try {
        return new anchor.web3.PublicKey(candyMcId);
      } catch (e) {
        console.log("Failed to construct CandyMachineId", e);
        return undefined;
      }
    }
  };

  const getCandyMachineIdByAsset = (candy_machine_id: String) => {
    //console.log("candyMcId from other component ====> ", candy_machine_id);
    setCandyMcId(candy_machine_id);
  };

  let error: string | undefined = undefined;

  if (process.env.REACT_APP_SOLANA_NETWORK === undefined) {
    error =
      "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
  } else if (process.env.REACT_APP_SOLANA_RPC_HOST === undefined) {
    error =
      "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
  }

  const candyMachineId = getCandyMachineId();

  const paypalScriptOptions: PayPalScriptOptions = {
    "client-id": `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
    currency: "USD",
  };

  return (
    <PayPalScriptProvider options={paypalScriptOptions}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets}>
          <WalletDialogProvider>
            <div>
              {/* chat box  */}
              <Chatbox />

              <BrowserRouter>
                <HelmetProvider>
                  <Helmet>
                    <title>
                      RightsMint - The Benchmark of Intellectual Property
                    </title>
                  </Helmet>
                  <React.Suspense
                    fallback={
                      <div
                        style={{
                          background: "black",
                          width: "100%",
                          height: "100vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={RippleLoader}
                          alt="loader"
                          style={{ margin: "0 auto", display: "block" }}
                        />
                      </div>
                    }
                  >
                    <Routes>
                      <Route
                        path={RouteName.getPaypalToken}
                        element={
                          <>
                            <LazyGetPaypalToken />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.AdminLogin}
                        element={
                          <>
                            <LazyLogin />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.Register}
                        element={
                          <>
                            <LazyRegister />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.landing}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyLanding />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.tv}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyTv />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                       <Route
                        path={RouteName.help}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyHelp />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.campaign}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyCampaign />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.press}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyPress />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.campaignCopy}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyCampaignCopy />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.cookie}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyCookie />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.terms}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyTerms />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.privacy}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyPrivacy />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.accessibility}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyAccessibility />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.home}
                        element={
                          <>
                            <LazyNavbar />
                            <Home />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.emailVerifiedPage}
                        element={
                          <>
                            <LazyNavbar />
                            <LazyEmailVerify />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.registerSuccess}
                        element={
                          <>
                            <LazyNavbar />
                            <LazyRegisterSuccessPage />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.paymentSuccess}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyPaymentSuccessPage />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.paymentFailed}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyPaymentFailedPage />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.marketplace}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyMarketPlace />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.indexpool}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyIndexPool />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path="/indexpool-beta"
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyIndexPoolBeta />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.emailResendPage}
                        element={
                          <>
                            <LazyResendEmail />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.forgotPassword}
                        element={
                          <>
                            <LazyForgotPassword />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.resetPassword}
                        element={
                          <>
                            <LazyResetPassword />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.mediaOfferings}
                        element={
                          <>
                            <LazyNavbar />
                            <LazyRequireAuth
                              component={LazyMediaOfferings}
                              allowedRole={[role.user, role.owner]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.loyalties}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyLoyalties
                              candyMachineId={candyMachineId}
                              connection={connection}
                              txTimeout={DEFAULT_TIMEOUT}
                              rpcHost={rpcHost}
                              network={network}
                              getCandyMachineIdByAsset={
                                getCandyMachineIdByAsset
                              }
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.loyalties}
                        element={
                          <>
                            <LazyNavbar />
                            <LazyLoyalties
                              candyMachineId={candyMachineId}
                              connection={connection}
                              txTimeout={DEFAULT_TIMEOUT}
                              rpcHost={rpcHost}
                              network={network}
                              getCandyMachineIdByAsset={
                                getCandyMachineIdByAsset
                              }
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.loyaltiesRoom}
                        element={
                          <>
                            <LazyNavbar />
                            <LazyLoyaltiesRoom />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.loyaltiesSubscribe}
                        element={
                          <>
                            <LazyNavbar />
                            <LazyLoyaltiesSubscribe />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.CreateAsset}
                        element={
                          <>
                            <LazyAdminNavbar />
                            <LazyRequireAuth
                              component={LazyCreateAsset}
                              allowedRole={[role.admin, role.superAdmin]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.UpdateAsset}
                        element={
                          <>
                            <LazyAdminNavbar />
                            <LazyRequireAuth
                              component={LazyUpdateAsset}
                              allowedRole={[role.admin, role.superAdmin]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.CreateAssetOwner}
                        element={
                          <>
                            <LazyNavbar />
                            <LazyRequireAuth
                              component={LazyCreateAsset}
                              allowedRole={[role.owner]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.assumptions}
                        element={
                          <>
                            <LazyAdminNavbar />
                            <LazyRequireAuth
                              component={LazyAssumptions}
                              allowedRole={[role.admin]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.editAssumptions}
                        element={
                          <>
                            <LazyAdminNavbar />
                            <LazyRequireAuth
                              component={LazyEditAssumptions}
                              allowedRole={[role.admin]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.Dashboard}
                        element={
                          <>
                            <LazyAdminNavbar />
                            <LazyRequireAuth
                              component={LazyDashboard}
                              allowedRole={[role.admin, role.superAdmin]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                        <Route
                        path={RouteName.SDashboard}
                        element={
                          <>
                            <LazySAdminNavbar />
                            <LazySDashboard/>
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.Minting}
                        element={
                          <>
                            <LazyAdminNavbar />
                            <LazyRequireAuth
                              component={LazyMinting}
                              allowedRole={[role.admin, role.superAdmin]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.Listing}
                        element={
                          <>
                            <LazyAdminNavbar />
                            <LazyRequireAuth
                              component={LazyListing}
                              allowedRole={[role.admin, role.superAdmin]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.Users}
                        element={
                          <>
                            <LazyAdminNavbar />
                            <LazyRequireAuth
                              component={LazyUsers}
                              allowedRole={[role.admin, role.superAdmin]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.Analytics}
                        element={
                          <>
                            <LazyAdminNavbar />
                            <LazyRequireAuth
                              component={LazyAnalytics}
                              allowedRole={[role.admin, role.superAdmin]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.Launchpad}
                        element={
                          <>
                            <LazyAdminNavbar />
                            <LazyRequireAuth
                              component={LazyLaunchpad}
                              allowedRole={[role.admin, role.superAdmin]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.ExploreAlbumTrack}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyExploreAlbumTrack />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.Thankyou}
                        element={
                          <>
                            <LazyNavbar />
                            <LazyThankYou />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.Cart}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyCart />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.Profile}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyRequireAuth
                              component={LazyProfile}
                              allowedRole={[role.user, role.owner]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      <Route
                        path={RouteName.EditProfile}
                        element={
                          <>
                            <LazyBluredNavbar />
                            <LazyRequireAuth
                              component={LazyEditProfilePage}
                              allowedRole={[role.user, role.owner]}
                            />
                            <LazyLandingFooter />
                          </>
                        }
                      />
                      {/* <Route
                  path={RouteName.Settings}
                  element={
                    <>
                      <Navbar />
                      <RequireAuth
                        component={Settings}
                        allowedRole={[role.user, role.owner]}
                      />
                      <PromoFooter />
                    </>
                  }
                /> */}
                      <Route
                        path={RouteName.Unauthorized}
                        element={<LazyUnauthorized />}
                      />
                      <Route path="*" element={<LazyPageNotFound />}></Route>
                    </Routes>
                  </React.Suspense>
                </HelmetProvider>
              </BrowserRouter>

              {/* <BrowserRouter>
        <Routes>
          <Route
            path={RouteName.login}
            element={
              <>
                <Login />
              </>
            }
          />
          <Route
            path={RouteName.loyalties}
            element={
              <>
                <Navbar />
                <Loyalties />
                <Footer />
              </>
            }
          />
        </Routes>
    </BrowserRouter> */}
            </div>
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </PayPalScriptProvider>
  );
}

export default App;
