import React, { Component, Props, ReactDOM } from 'react';
import { Link, useNavigate } from "react-router-dom";
import AudioModule from '../../../../components/shared/AudioModule';
import "../components.css";
import Moment from 'react-moment';
import { AssetsType } from '../../../../enum/AssetsType.enum';
import AssetsDataService from '../../../../services/AssetsService';

function HomeCard({ asset_id, name, author, image, starting_price, valuation, created_at, quantity, type, cdn }: any) {
    const navigate = useNavigate();

   const navigateTO = () => {
        const assetName = name.replace(/\s+/g, '-').toLowerCase()
        const authorName = author.replace(/\s+/g, '-').toLowerCase()
        navigate(`/launchpad/${authorName}-${assetName}`);
    }

    const navigateTOTrack = () => {
        const assetName = name.replace(/\s+/g, '-').toLowerCase()
        const authorName = author.replace(/\s+/g, '-').toLowerCase()
        navigate(`/album/${authorName}-${assetName}`);
    }

    return (
        // <Link to={`/loyalties/${asset_id}`} style={{ textDecoration: 'none' }}>
        <div className="best-performing-card" onClick={AssetsType[type] === 'Album' ? navigateTOTrack : navigateTO}  style={{ cursor: 'pointer' }} id="add-min-height">
            {/* card image */}
            <div
                className="best-performing-bg-img"
                style={{
                    background: `url(${image}) center no-repeat`,
                    backgroundSize: "cover",
                    zIndex: 30,
                }}
            >
                {AssetsType[type] === 'Music' ||  AssetsType[type] === 'Album' && cdn !== null ?
                    <div className='audio-placement'><AudioModule data={cdn} /></div>
                    :
                    <></>
                }
            </div>

            <div className="best-performing-content">
                <div id='desktop-card-price'>
                    <p className="best-performing-title">
                        {name.length < 45 ? (
                            <>{`${name.substring(0, 45)}`}</>
                        ) : (
                            <>{`${name.substring(0, 45)}..`}</>
                        )}
                    </p>
                    <p className="red-text-back"> <Moment format="llll">
                        {created_at}
                    </Moment></p>
                    <div className="best-performing-bottom-section">
                        <p className="best-performing-rmt">{valuation} SOL</p>
                        <p className="best-performing-gray-text2 ">to be reached</p>
                    </div>

                    <div className="best-performing-bottom-section">
                        <p className="best-performing-gray-text">${AssetsDataService.solToUSD(valuation)}</p>
                        <p className="best-performing-rmt black-text">{quantity} NFTs</p>
                    </div>
                </div>

                <div id='responsive-card-price'>
                    <p className="best-performing-title">
                        {name.length < 45 ? (
                            <>{`${name.substring(0, 45)}`}</>
                        ) : (
                            <>{`${name.substring(0, 45)}..`}</>
                        )}
                    </p>
                    <p className="heighest-ranking-album-rmt">{starting_price} SOL</p>
                    <p className="red-text-back">May 30 | 2:00 PM UTC</p>

                    <p className="best-performing-gray-rev" style={{ marginBottom: '0.4rem' }}>
                        Revenue
                    </p>
                    <p className="best-performing-bold">{starting_price} / <span className='best-performing-gray-text'>12 months</span></p>
                </div>
            </div>





        </div>

        // </Link>
    );
}

export default HomeCard;
