import React, { useEffect, useState } from "react";
import { HiMail } from "react-icons/hi";
import { Helmet } from "react-helmet-async";

import './chatbox.css'

function Chatbox() {

    const [showChat, setShowChat] = useState(false)
    let chat = document.querySelector('.globalClass_c5c7')

    useEffect(() => {


        chat?.classList.add('hide-chat')


    }, [])



    const toggleChat = () => {
        setShowChat((val) => !val)

        // chat?.classList.toggle("hide-chat")

        if (showChat) {
            chat?.classList.add('hide-chat')
            chat?.classList.remove('show-chat')
        } else {
            chat?.classList.add('show-chat')
            chat?.classList.remove('hide-chat')
        }
    }


    return (
        <>
            {/* {showChat ?
                <Helmet>
                    <script src="//code.jivosite.com/widget/nU9QpN07gt" async id='chat'></script>
                </Helmet>
                :
                <></>} */}
            {/* <button className={showChat ? "chat-button-active" : "chat-button"} onClick={toggleChat}><HiMail /></button> */}
        </>

    )
}

export default Chatbox
