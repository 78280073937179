import { createSlice } from "@reduxjs/toolkit";

const adminSettingSlice = createSlice({
  name: "adminSettings",
  initialState: {
    buttonStatus: null,
    countDownDate: null,
    countDownStatus: null,
    mintStatus: null,
    paypalLoginStatus :null,
  },
  reducers: {
    setButtonStatus: (state: any, action: any) => {
      state.buttonStatus = action.payload.buttonStatus;
    },
    setCountDownDate: (state: any, action: any) => {
      state.countDownDate = action.payload.countDownDate;
    },
    setCountDownStatus: (state: any, action: any) => {
      state.countDownStatus = action.payload.countDownStatus;
    },
    setMintStatus: (state: any, action: any) => {
      console.log("action.payload setMintStatus ", action.payload);
      state.mintStatus = action.payload.mintStatus;
    },
    setPaypalLoginStatus: (state: any, action: any) => {
      state.paypalLoginStatus = action.payload.paypalLoginStatus;
    },
  },
});

export const {
  setButtonStatus,
  setCountDownDate,
  setCountDownStatus,
  setMintStatus,
  setPaypalLoginStatus,
} = adminSettingSlice.actions;

export default adminSettingSlice.reducer;
