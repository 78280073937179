import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import AudioModule from '../../../../components/shared/AudioModule';
import { HiOutlineHeart } from "react-icons/hi";
import '../components.css'
import { AssetsType } from '../../../../enum/AssetsType.enum';
function HeighestRankedCollection({ asset_id, name, author, image, starting_price, valuation, quantity, type, cdn }: any) {
    const navigate = useNavigate();

   const navigateTO = () => {
        const assetName = name.replace(/\s+/g, '-').toLowerCase()
        const authorName = author.replace(/\s+/g, '-').toLowerCase()
        navigate(`/launchpad/${authorName}-${assetName}`);
    }

    const navigateTOTrack = () => {
        const assetName = name.replace(/\s+/g, '-').toLowerCase()
        const authorName = author.replace(/\s+/g, '-').toLowerCase()
        navigate(`/album/${authorName}-${assetName}`);
    }
    
    return (
        // <Link to={`/loyalties/${asset_id}`} style={{ textDecoration: 'none' }}>
        <div className="ranked-collection-container" onClick={AssetsType[type] === 'Album' ? navigateTOTrack : navigateTO} style={{ cursor: 'pointer' }}>
            <div className="ranked-collection-img-section">

                <div
                    className="ranked-collection-img "
                    style={{
                        background: `url(${image}) center no-repeat`,
                        backgroundSize: 'cover',
                        zIndex: 30,
                    }}
                >
                    <div className="heart-circle">
                        <HiOutlineHeart />
                    </div>
                </div>
                {/* {AssetsType[type] === 'Music' && cdn !== null ?
                    <div className='audio-placement'><AudioModule data={cdn} /></div>
                    :
                    <></>} */}
            </div>

            <div className="ranked-collection-content">
                <p className="ranked-collection-title">{name}</p>
                <p className="ranked-collection-number">#{asset_id}</p>
                {type === 'Common' ?
                    <p className="bg-green ranked-collection-type">
                        {AssetsType[type]}
                    </p> :
                    <p className="bg-rare ranked-collection-type">
                        {AssetsType[type]}
                    </p>}

                <p className="button ranked-collection-rmt" >{valuation} SOL</p>
                <p className="ranked-collection-dollar">${starting_price}</p>
            </div>
        </div>
        // </Link>

    )
}

export default HeighestRankedCollection