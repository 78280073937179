import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import AudioModule from '../../../../components/shared/AudioModule';
import { AssetsType } from '../../../../enum/AssetsType.enum';
import '../components.css'
function BestPerformingCard({ asset_id, name, author, image, starting_price, valuation, quantity, type, cdn, revenue }: any) {
    const navigate = useNavigate();

   const navigateTO = () => {
        const assetName = name.replace(/\s+/g, '-').toLowerCase()
        const authorName = author.replace(/\s+/g, '-').toLowerCase()
        navigate(`/launchpad/${authorName}-${assetName}`);
    }
    
    const navigateTOTrack = () => {
        const assetName = name.replace(/\s+/g, '-').toLowerCase()
        const authorName = author.replace(/\s+/g, '-').toLowerCase()
        navigate(`/album/${authorName}-${assetName}`);
    }

    return (
        // <Link to={`/loyalties/${asset_id}`} style={{ textDecoration: 'none' }}>
        <div className="best-performing-card" onClick={AssetsType[type] === 'Album' ? navigateTOTrack : navigateTO}  style={{ cursor: 'pointer' }}>

            {/* card image */}
            <div
                className="best-performing-bg-img"
                style={{
                    background: `url(${image}) center no-repeat`,
                    backgroundSize: 'cover',
                    zIndex: 30,

                }}
            >
                {AssetsType[type] === 'Music' ||  AssetsType[type] === 'Album' && cdn !== null ?
                    <div className='audio-placement'><AudioModule data={cdn} /></div>
                    :
                    <></>}
            </div>

            <div className="best-performing-content">
                <div id='desktop-card-price'>
                    <p className="best-performing-title">
                        {name.length < 50 ?
                            <>
                                {`${name.substring(0, 50)}`}
                            </> :
                            <>
                                {`${name.substring(0, 50)}..`}
                            </>}
                    </p>

                    <div className="best-performing-bottom-section">
                        <p className="best-performing-rmt">{valuation} SOL</p>
                        <p className="best-performing-gray-text2">
                            Revenue
                        </p>
                    </div>

                    <div className="best-performing-bottom-section">
                        <p className="best-performing-gray-text ">
                            {quantity} NFTs
                        </p>
                        <p className="best-performing-bold">${revenue} / <span className='best-performing-gray-text'>12 months</span></p>
                    </div>
                </div>

                {/* responsive */}
                <div id='responsive-card-price'>
                    <p className="heighest-ranking-album-title">
                        {name.length < 40 ?
                            <>
                                {`${name.substring(0, 40)}`}
                            </> :
                            <>
                                {`${name.substring(0, 40)}..`}
                            </>}
                    </p>


                    <p className="heighest-ranking-album-rmt">{starting_price} SOL</p>
                    <p className="best-performing-gray-text">
                        ${starting_price}
                    </p>

                    <p className="best-performing-gray-rev" style={{ marginBottom: '0.4rem' }}>
                        Revenue
                    </p>
                    <p className="best-performing-bold">{starting_price} / <span className='best-performing-gray-text'>12 months</span></p>

                </div>
            </div>
        </div>
        // </Link>
    )
}

export default BestPerformingCard
