export enum RouteName {
  landing = "/",
  home = "/home",
  mediaOfferings = "/mediaOfferings",
  loyalties = "/launchpad/:assetName",
  loyaltiesRoom = "/loyaltiesRoom",
  loyaltiesSubscribe = "/loyaltiesSubscribe/:assetName",
  login = "/login/:id",
  AdminLogin = "/login/rmt-admin-2022",
  CreateAsset = "/admin/CreateAsset",
  UpdateAsset = "/admin/update-asset/:asset_id",
  CreateAssetOwner = "/owner/CreateAsset",
  SDashboard = "/seller/dashboard",
  SMinting = "/seller/minting",
  SListing = "/seller/listing",
  SUsers = "/seller/users",
  SAnalytics = "/seller/analytics",
  SLaunchpad = "/seller/launchpad",
  Dashboard = "/admin/dashboard",
  Minting = "/admin/minting",
  Listing = "/admin/listing",
  Users = "/admin/users",
  Analytics = "/admin/analytics",
  Launchpad = "/admin/launchpad",
  ExploreAlbumTrack = "/album/:assetName",
  Cart = "/cart",
  Profile = "/user/profile/*",
  EditProfile = "/user/editProfile",
  Settings = "user/settings",
  Thankyou = "Thankyou",
  Register = "/register",
  Unauthorized = "/unauthorized",
  emailVerifiedPage = "/user/email/verified",
  emailResendPage = "/user/resend/email",
  campaign = "/closed-alpha",
  campaignCopy = "/signup",
  cookie = "/cookie-policy",
  terms = "/terms",
  privacy = "/privacy-policy",
  accessibility = "/accessibility",
  forgotPassword = "/user/forgot-password",
  resetPassword = "/reset-password/:resetToken",
  registerSuccess = "/register/success",
  assumptions = "/admin/assumptions/:assetName",
  editAssumptions = "/admin/edit-assumptions/:assetName",
  marketplace = "/marketplace",
  indexpool = "/Liquidity/*",
  tv = "/tv",
  press = "/press-release",
  getPaypalToken = "/get/paypal/token",
  paymentSuccess = "/payment/success",
  paymentFailed = "/payment/failed",
  help="/about-us"
}
