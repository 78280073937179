import React, { useState, useEffect, ChangeEvent } from "react";
import './home.css'
import storeFront from '../../images/storeFront1.png';
import banner from '../../images/banner.png';
import Creator from './components/shared/Creator';
import BestPerformingCard from './components/shared/BestPerformingCard';
import HeighestRankingAlbum from './components/shared/HeighestRankingAlbum';
import TopSellingCard from './components/shared/TopSellingCard';
import HeighestRankedCollection from './components/shared/HeighestRankedCollection';
import BgImage from '../../images/bg.png';
import Dots_circle from '../../images/dots_circle.png';
import CardCarousel from './components/CardCarousel';
import { Link } from 'react-router-dom';
import IAssetsData from '../../types/Assets';
import AssetsDataService from "../../services/AssetsService";
import Ripple from "../../components/Ripple";
import { useSelector } from "react-redux";
import { setTimeout } from "timers";
import { AnyARecord } from "dns";

const Home: React.FC = () => {
    const [assets, setAssets] = useState<Array<IAssetsData>>([]);
    const [user, setuser] = useState(false);

    const gated_access = localStorage.getItem("gated-token");
    // console.log(gated_access);

    // condition for loader
    const [isPending, setIsPending] = useState(true)
    const currentUser = useSelector(
        (state: any) => state?.user?.currentUser?.data
    );

    useEffect(() => {
        setTimeout(() => {
            retrieveAssets()
        }, 1000)
    }, []);

    useEffect(() => {
        currentUser ? setuser(true) : setuser(false);
    }, [currentUser]);

    const retrieveAssets = () => {
        AssetsDataService.getAssetsAll()
            .then((response: any) => {
                setAssets(response.data.data);
                // console.log("assets all", response.data.data)
                setIsPending(false)
            })
            .catch((e: Error) => {
                // console.log(e);
            });
    };

    const refreshList = () => {
        retrieveAssets();
    };

    return (
        <> <div
            id='back-img'
            style={{
                background: `url(${BgImage}) center no-repeat`,
                backgroundSize: 'cover',
                position: 'absolute',
                zIndex: -10,
                height: '500px',
                top: '32px',
                overflow: 'hidden',
                width: '100%',

            }}
        >

        </div>


            <div className='desktop-2'>
                <div className="page" style={{ position: "relative" }}>
                    <div className="storeFrontPage">
                        <div className="storeFrontPage_header">
                            <div className="header_text">
                                <p className="title">
                                    Get rewards and loyalties from artists, songs and content creators!
                                </p>
                                <p className="infooo">
                                    Over 100,000 songs are available, and here’s an opportunity to own a piece of history from your favourite artist!
                                </p>

                                <div className="buttons" id='header-buttons'>
                                    <Link to="/mediaOfferings" style={{ textDecoration: 'none', marginRight: '10px' }}>
                                        <button className="button">Explore</button>
                                    </Link>
                                    {/* {user ? (
                                        <Link to="/CreateAsset" style={{ textDecoration: 'none', marginRight: '10px' }}>
                                            <button className="bg-btn-outline" style={{ marginTop: '10px' }}>Create your own</button>
                                        </Link>

                                    ) : (
                                        <Link to="/closed-alpha" style={{ textDecoration: 'none', marginRight: '10px' }}>
                                            <button className="bg-btn-outline" style={{ marginTop: '10px' }}>Create your own</button>
                                        </Link>

                                    )} */}
                                </div>

                            </div>

                            <div className="header_image">
                                <img src={storeFront} alt="" />
                            </div>
                        </div>

                        <div className="storeFrontPage_middle" style={{ marginTop: '80px' }}>
                            <div className="storeFrontPage_middle_header_top">
                                <div>
                                    <p className='initial-media'>Initial media offerings</p>
                                    <p className="initial-info">Don’t miss a new IMO hop on it, keep updated with the most promising NFTs projects released.</p>
                                </div>
                                {/* <button className="button">All upcoming</button> */}
                            </div>

                        </div>

                    </div>
                    {/* carousel */}
                    {!isPending ? <div
                        id='back-img'
                        style={{
                            background: `url(${Dots_circle}) center no-repeat`,
                            backgroundSize: 'cover',
                            position: 'absolute',
                            left: '-200px',
                            zIndex: -10,
                            top: '600px',
                            height: '400px',
                            overflow: 'hidden',
                            width: '400px',
                        }}
                    >
                    </div> : <></>}

                    {!isPending ? <CardCarousel data={assets} /> : <Ripple />}
                </div>
                {/* { BestPerformingCard section} */}
                <div className="storeFrontPage" style={{ marginTop: '20px', position: 'relative' }}>
                    <div className="storeFrontPage_middle">
                        <div className="storeFrontPage_middle_header">
                            <p>Best performing project this week</p>
                            {/* <button className="button">View all</button> */}

                        </div>
                        {!isPending ? <div
                            id='back-img'
                            style={{
                                background: `url(${Dots_circle}) center no-repeat`,
                                backgroundSize: 'cover',
                                position: 'absolute',
                                right: '-500px',
                                zIndex: -10,
                                top: '100px',
                                height: '500px',
                                overflow: 'hidden',
                                width: '500px',
                            }}
                        >
                        </div> : <></>}
                        {!isPending ?
                            <div className='best-performing-section'>
                                {assets && assets.filter(function (card: any) {
                                    return card.status === 1;
                                }).slice(0, 3).map((asset, index) => (
                                    <BestPerformingCard {...asset} key={index} />
                                ))}</div> : <Ripple />}


                    </div>
                </div>

                {/* banner */}
                <div className="banner-area">
                    <div className="banner" style={{ marginTop: '60px', position: 'relative' }}>

                        {!isPending ? <div
                            id='back-img'
                            style={{
                                background: `url(${Dots_circle}) center no-repeat`,
                                backgroundSize: 'cover',
                                position: 'absolute',
                                left: '-175px',
                                zIndex: -10,
                                top: '25px',
                                height: '350px',
                                overflow: 'hidden',
                                width: '350px',
                            }}
                        >
                        </div> : <></>}
                        <div className="banner_info">
                            <div className="title">
                                Own a part of iconic albums
                            </div>
                            <div className="info">
                                Cherish diverse ranges of iconic albums by the Jackson 5, Bee Gees, Eminem, Snoop Dogg, and Sex Pistols.
                            </div>
                            <button >Explore albums</button>
                        </div>
                        <div className="banner_image">
                            <img className="banner_major_image" src={banner} alt="" />
                        </div>

                    </div>
                </div>


                {/* { Heighest ranked album} */}
                {assets && assets.filter(function(card:any){
                    return card.type === "4";
                }).map((asset, index) => (
                    <div className="storeFrontPage" style={{ marginTop: '20px', position: 'relative' }}>
                        {!isPending ? <div
                            id='back-img'
                            style={{
                                background: `url(${Dots_circle}) center no-repeat`,
                                backgroundSize: 'cover',
                                position: 'absolute',
                                right: '-350px',
                                zIndex: -10,
                                top: '-250px',
                                height: '700px',
                                overflow: 'hidden',
                                width: '700px',
                            }}
                        ></div> : <></>}
                        <div className="storeFrontPage_middle">
                            <div className="storeFrontPage_middle_header">
                                <p>Highest ranked albums</p>

                            </div>

                            {!isPending ? <div className='heighest-ranking-album-section'>
                                {assets &&
                                    assets.filter(function (card: any) {
                                        return card.type === "4";
                                    }).slice(0, 8).map((asset, index) => (
                                        <HeighestRankingAlbum {...asset} key={index} />
                                    ))}</div> : <Ripple />}


                        </div>
                    </div>
                    ))}

                {/* top sellings items */}
                <div className="top-selling-full-screen">
                    <div className="storeFrontPage" style={{ marginTop: '20px' }}>
                        <div className="storeFrontPage_middle">
                            <div className="storeFrontPage_middle_header" id='top-selling-items'>
                                <p>Top selling items over <span className='red-text'>Last 7 days</span></p>
                                {/* <button className='button'>View all rankings</button> */}
                            </div>

                            {!isPending ? <div className='top-ranking-section'>
                                {assets &&
                                    assets.filter(function (card: any) {
                                        return card.status === 1;
                                    }).map((asset, index) => (
                                        <TopSellingCard {...asset} key={index} />
                                    ))}
                            </div> : <Ripple />}

                        </div>
                    </div>
                </div>

                {/* Highest ranked per collection */}

                <div className="storeFrontPage" style={{ marginTop: '20px', position: 'relative' }}>
                    {!isPending ? <div
                        id='back-img'
                        style={{
                            background: `url(${Dots_circle}) center no-repeat`,
                            backgroundSize: 'cover',
                            position: 'absolute',
                            left: '-500px',
                            zIndex: -10,
                            top: '750px',
                            height: '400px',
                            overflow: 'hidden',
                            width: '400px',
                        }}
                    ></div> : ''}
                    <div className="storeFrontPage_middle">
                        <div className="storeFrontPage_middle_header">
                            <p>Highest ranked per collection</p>

                        </div>

                        {!isPending ? <div className='rank-collection' style={{ marginTop: '40px' }}>
                            {assets &&
                                assets.filter(function (card: any) {
                                    return card.status === 1;
                                }).slice(0, 8).map((asset, index) => (
                                    <HeighestRankedCollection {...asset} key={index} />
                                ))}
                        </div> : <Ripple />}


                    </div>
                </div>

                {/* creator section */}
                <div className="storeFrontPage" style={{ marginTop: '20px', position: 'relative' }}>
                    {!isPending ? <div

                        style={{
                            background: `url(${Dots_circle}) center no-repeat`,
                            backgroundSize: 'cover',
                            position: 'absolute',
                            right: '-150px',
                            zIndex: -10,
                            top: '0px',
                            height: '400px',
                            overflow: 'hidden',
                            width: '400px',
                        }}
                    ></div> : <></>}
                    {/* <div className="storeFrontPage_middle">
                        <div className="storeFrontPage_middle_header">
                            <p>Notable creators</p>
                            <button className="button">View all</button>
                        </div>


                        {!isPending ? <div className="storeFrontPage_middle_collection">{cardItems.slice(0, 10).map((item, index) => (
                            <Creator key={index} {...item} />
                        ))}</div> : <Ripple />}

                    </div> */}
                </div>

            </div>
        </>
    )
}

export default Home