import http from "../http-common";
import IFavouriteData from "../types/Favourite";
import authHeader from "./auth-header";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;

const getCart = () => {
  return http.get<Array<any>>("/");
};


const getCartByUserId = async () => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/cart/getCartByUserId`,
    headers: authHeader(),
  });
};

const saveCart = async (favJSON: any) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/cart`,
    data: favJSON,
    headers: authHeader(),
  });
  return response;
};

const directCheckout = async (Direct: any) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/cart/directCheckout`,
    data: Direct,
    headers: authHeader(),
  });
  return response;
};

const cartCheckout = async (favJSON: any) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/cart/checkout`,
    data: favJSON,
    headers: authHeader(),
  });
  return response;
};

const removeCart = async (favJSON: any) => {
  const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/cart`,
    data: favJSON,
    headers: authHeader(),
  });
  return response;
};

const clearUserCart = async (favJSON: any) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/cart/clearUserCart`,
    data: favJSON,
    headers: authHeader(),
  });
  return response;
};

const getTotalQtybyAssetID = async (asset_id: any) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/cart/getItemtotalQty/${asset_id}`,
  });
  return response;
};

const activeCartTrigger = async (favJSON: any) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/cart/activeCartTrigger`,
    data: favJSON,
    headers: authHeader(),
  });
  return response;
};

const CartService = {
  getCart,
  getCartByUserId,
  saveCart,
  removeCart,
  cartCheckout,
  getTotalQtybyAssetID,
  directCheckout,
  clearUserCart,
  activeCartTrigger
};

export default CartService;
