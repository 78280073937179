import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import '../components.css'
function TopSellingCard({ asset_id, name, author, image, starting_price, valuation, quantity }: any) {
    const navigate = useNavigate();
   const navigateTO = () => {
        const assetName = name.replace(/\s+/g, '-').toLowerCase()
        const authorName = author.replace(/\s+/g, '-').toLowerCase()
        navigate(`/launchpad/${authorName}-${assetName}`);
    }
    return (
        <div onClick={navigateTO} style={{ textDecoration: 'none' }}>

            <div className="top-ranking-container">

                <img
                    className="top-ranking-img_box"
                    src={`${image}`}
                    alt=""
                />

                <div className="top-ranking-content">
                    <div className='top-ranking-album-title'>
                        {name.length < 40 ?
                            <>
                                {`${name.substring(0, 43)}`}
                            </> :
                            <>
                                {`${name.substring(0, 43)}..`}
                            </>}
                    </div>
                    <div className="top-ranking-price">
                        <div className="top-ranking-percent">+30.56%</div>
                        <div className="top-ranking-dollar">${valuation}</div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TopSellingCard