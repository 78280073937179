import http from "../http-common";
import IAssetsData from "../types/Assets";
import authHeader from "./auth-header";
import AuthHeaderService from "./AuthHeaderService";

import axios from "axios";
import { useSelector } from "react-redux";
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER;

const DOLLAR_RATE = 32.99;

const getAssetsAll = () => {
  return http.get<Array<any>>("/assets");
};

const getAssetsAllNotApproved = () => {
  return http.get<Array<any>>("/assets/temp");
};

const getListedAssetsAll = () => {
  return http.get<Array<any>>("/assets/listed");
};

const getAssets = (id: any) => {
  return http.get<IAssetsData>(`/assets/${id}`);
};

const getTracksbyAssetId = (assetID: any) => {
  return http.get<Array<any>>(`/assets/trackList/${assetID}`);
};

const getAssumptionsbyAssetId = (assetID: any) => {
  return http.get<Array<any>>(`/assets/assumptions/${assetID}`);
};

const saveAsset = async (
  albumId: any,
  values: any,
  trackList: any,
  imageFile: any,
  imageFile2: any,
  user_id: any
) => {
  const formData = new FormData();
  formData.append("name", values.albumName);
  formData.append("album_id", albumId);
  formData.append("author", values.author);
  formData.append("revenue", values.revenue);
  formData.append("owner_id", user_id);
  formData.append("sku_id", "0");
  formData.append("contract_id", "0");
  formData.append("description", values.description);
  formData.append("genre_id", values.genre_id);
  formData.append("quantity", values.quantity);
  formData.append("type", values.type);
  formData.append("share_percentage", values.share_percentage);
  formData.append("starting_price", values.starting_price);
  formData.append("buy_count", values.buy_count);
  formData.append("record_lable_name", values.record_lable_name);
  formData.append("isrc_code", values.isrc_code);
  formData.append("publisher", values.publisher);
  formData.append("composer", values.composer);
  formData.append("catalogue_number", values.catalogue_number);
  formData.append("barcode", values.barcode);
  formData.append("minting_type", values.minting_type);
  formData.append("release_date", values.release_date);
  formData.append("series", values.series);
  formData.append("format", values.format);
  formData.append("country", values.country);
  formData.append("style", values.style);
  formData.append("credits", values.credits);
  formData.append("notes", values.notes);
  formData.append("copyright_id", values.copyright_id);
  formData.append("image", imageFile);
  formData.append("copyright_certificate", imageFile2);
  formData.append("trackList", JSON.stringify(trackList));

  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/assets`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
  console.log("Asset created --- ", response);
  return response;
};

const updateAsset = async (
  asset_id: any,
  values: any,
  trackList: any,
  imageFile: any,
  imageFile2: any,
  user_id: any
) => {
  const formData = new FormData();

  if (imageFile) {
    formData.append("image", imageFile);
  } else {
    formData.append("image", values.image_filename);
  }

  if (imageFile2) {
    formData.append("copyright_certificate", imageFile2);
  } else {
    formData.append(
      "copyright_certificate",
      values.copyright_certificate_filename
    );
  }

  formData.append("name", values.albumName);
  formData.append("album_id", values.album_id);
  formData.append("author", values.author);
  formData.append("revenue", values.revenue);
  formData.append("description", values.description);
  formData.append("genre_id", values.genre_id);
  formData.append("quantity", values.quantity);
  formData.append("type", values.type);
  formData.append("share_percentage", values.share_percentage);
  formData.append("starting_price", values.starting_price);
  formData.append("buy_count", values.buy_count);
  formData.append("record_lable_name", values.record_lable_name);
  formData.append("isrc_code", values.isrc_code);
  formData.append("publisher", values.publisher);
  formData.append("composer", values.composer);
  formData.append("catalogue_number", values.catalogue_number);
  formData.append("barcode", values.barcode);
  formData.append("minting_type", values.minting_type);
  formData.append("valuation", values.valuation);
  formData.append("collection_id", values.collection_id);
  formData.append("external_url", values.external_url);
  formData.append("animation_url", values.animation_url);
  formData.append("contract_address", values.contract_address);
  formData.append("owner_id", values.owner_id);
  formData.append("copyright_id", values.copyright_id);
  formData.append("transaction_fee", values.transaction_fee);
  formData.append("status", values.status);
  formData.append("sku_id", values.sku_id);
  formData.append("contract_id", values.contract_id);
  formData.append("config_id", values.config_id);
  formData.append("cdn", values.cdn);
  formData.append("asset_id", asset_id);
  formData.append("release_date", values.release_date);
  formData.append("series", values.series);
  formData.append("country", values.country);
  formData.append("style", values.style);
  formData.append("format", values.format);
  formData.append("credits", values.credits);
  formData.append("notes", values.notes);
  formData.append("temp_asset_id", values.temp_asset_id);

  formData.append("trackList", JSON.stringify(trackList));

  const response = await axios({
    method: "PATCH",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/assets/update`,
    data: formData,
    headers: AuthHeaderService.authHeaderWithMultipartForm(),
  });
  return response;
};

const createAssumptions = async (values: any, assetID: any) => {
  const formData = new FormData();
  formData.append("asset_id", assetID);
  formData.append("generated_revenue_24", values.generated_revenue_24);
  formData.append("cost_24", values.cost_24);
  formData.append("net_profit_24", values.net_profit_24);
  formData.append("net_profit_12", values.net_profit_12);
  formData.append("ip_valuation", values.ip_valuation);
  formData.append("ip_valuation_mark_up", values.ip_valuation_mark_up);
  formData.append("total_ip_valuation", values.total_ip_valuation);
  formData.append("percentage_minted", values.percentage_minted);
  formData.append("fair_ip_valuation", values.fair_ip_valuation);
  formData.append("suggested_mintable", values.suggested_mintable);
  formData.append("fair_public_mint", values.fair_public_mint);
  formData.append("discount_value_of_mint", values.discount_value_of_mint);
  formData.append("suggested_discount", values.suggested_discount);
  formData.append("suggested_public_mint", values.suggested_public_mint);
  formData.append("revenue_genrated", values.revenue_genrated);
  formData.append("total_net_profit_12", values.total_net_profit_12);
  formData.append(
    "total_net_profit_per_nft_12",
    values.total_net_profit_per_nft_12
  );
  formData.append(
    "ROI_without_valuating_cnft_12",
    values.ROI_without_valuating_cnft_12
  );
  formData.append("total_net_profit_24", values.total_net_profit_24);
  formData.append(
    "total_net_profit_per_nft_24",
    values.total_net_profit_per_nft_24
  );
  formData.append(
    "ROI_without_valuating_cnft_24",
    values.ROI_without_valuating_cnft_24
  );
  formData.append("expected_proce_increase", values.expected_proce_increase);
  formData.append("expected_price_decline", values.expected_price_decline);
  formData.append(
    "expected_value_of_nft_12_increase",
    values.expected_value_of_nft_12_increase
  );
  formData.append(
    "expected_value_of_nft_12_decrease",
    values.expected_value_of_nft_12_decrease
  );
  formData.append(
    "ROI_without_valuating_cnft_12_increase",
    values.ROI_without_valuating_cnft_12_increase
  );
  formData.append(
    "ROI_without_valuating_cnft_12_decrease",
    values.ROI_without_valuating_cnft_12_decrease
  );
  formData.append(
    "expected_value_of_nft_24_increase",
    values.expected_value_of_nft_24_increase
  );
  formData.append(
    "expected_value_of_nft_24_decrease",
    values.expected_value_of_nft_24_decrease
  );
  formData.append(
    "ROI_without_valuating_cnft_24_increase",
    values.ROI_without_valuating_cnft_24_increase
  );
  formData.append(
    "ROI_without_valuating_cnft_24_decrease",
    values.ROI_without_valuating_cnft_24_decrease
  );

  console.log("valuessss", values);

  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/assets/assumptions`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
  console.log("Assumption created --- ", response);
  return response;
};

const updateAssumptions = async (values: any, assetID: any) => {
  const formData = new FormData();
  formData.append("asset_id", assetID);
  formData.append("generated_revenue_24", values.generated_revenue_24);
  formData.append("cost_24", values.cost_24);
  formData.append("net_profit_24", values.net_profit_24);
  formData.append("net_profit_12", values.net_profit_12);
  formData.append("ip_valuation", values.ip_valuation);
  formData.append("ip_valuation_mark_up", values.ip_valuation_mark_up);
  formData.append("total_ip_valuation", values.total_ip_valuation);
  formData.append("percentage_minted", values.percentage_minted);
  formData.append("fair_ip_valuation", values.fair_ip_valuation);
  formData.append("suggested_mintable", values.suggested_mintable);
  formData.append("fair_public_mint", values.fair_public_mint);
  formData.append("discount_value_of_mint", values.discount_value_of_mint);
  formData.append("suggested_discount", values.suggested_discount);
  formData.append("suggested_public_mint", values.suggested_public_mint);
  formData.append("revenue_genrated", values.revenue_genrated);
  formData.append("total_net_profit_12", values.total_net_profit_12);
  formData.append(
    "total_net_profit_per_nft_12",
    values.total_net_profit_per_nft_12
  );
  formData.append(
    "ROI_without_valuating_cnft_12",
    values.ROI_without_valuating_cnft_12
  );
  formData.append("total_net_profit_24", values.total_net_profit_24);
  formData.append(
    "total_net_profit_per_nft_24",
    values.total_net_profit_per_nft_24
  );
  formData.append(
    "ROI_without_valuating_cnft_24",
    values.ROI_without_valuating_cnft_24
  );
  formData.append("expected_proce_increase", values.expected_proce_increase);
  formData.append("expected_price_decline", values.expected_price_decline);
  formData.append(
    "expected_value_of_nft_12_increase",
    values.expected_value_of_nft_12_increase
  );
  formData.append(
    "expected_value_of_nft_12_decrease",
    values.expected_value_of_nft_12_decrease
  );
  formData.append(
    "ROI_without_valuating_cnft_12_increase",
    values.ROI_without_valuating_cnft_12_increase
  );
  formData.append(
    "ROI_without_valuating_cnft_12_decrease",
    values.ROI_without_valuating_cnft_12_decrease
  );
  formData.append(
    "expected_value_of_nft_24_increase",
    values.expected_value_of_nft_24_increase
  );
  formData.append(
    "expected_value_of_nft_24_decrease",
    values.expected_value_of_nft_24_decrease
  );
  formData.append(
    "ROI_without_valuating_cnft_24_increase",
    values.ROI_without_valuating_cnft_24_increase
  );
  formData.append(
    "ROI_without_valuating_cnft_24_decrease",
    values.ROI_without_valuating_cnft_24_decrease
  );

  console.log("valuessss", values);

  const response = await axios({
    method: "PATCH",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/assets/assumptions`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
  console.log("Assumption created --- ", response);
  return response;
};

const approveAsset = async (id: any) => {
  const response = await axios({
    method: "PATCH",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/assets/${id}/approve`,
  });
  console.log("Asset approved --- ", response);
  return response;
};

const declineAsset = async (id: any) => {
  const response = await axios({
    method: "PATCH",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/verifyAsset/${id}/decline`,
  });
  console.log("Decline approved --- ", response);
  return response;
};

const updateMeta = async (data: any) => {
  const response = await axios({
    method: "PATCH",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/assets/updateMeta`,
    data: data,
    headers: { "Content-Type": "application/json; charset=utf-8" },
  });
  return response;
};

const userMint = async (data: any) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/minting`,
    data: data,
    headers: { "Content-Type": "application/json; charset=utf-8" },
  });
  return response;
};

const solToUSD = (data: any) => {
  let response = data / DOLLAR_RATE;
  return response;
};

const getAssetByAssetName = async (assetName: any) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/assets/assetName`,
    data: {
      asset_name: assetName,
    },
  });
  return response;
};

const getAssetByAssetID = async (asset_id: any) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/assets/${asset_id}`,
    headers: authHeader(),
  });
  return response;
};

const getTracksByID = async (asset_id: any) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/assets/trackList/${asset_id}`,
    headers: authHeader(),
  });
  return response;
};

const getTotalSharesByID = async (asset_id: any) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/assets/total-number-shares/${asset_id}`,
    headers: authHeader(),
  });
  return response;
};

// const verifyAsset = async (data:any) => {
//   const response = await axios({
//     method: "post",
//     url: `${process.env.REACT_APP_BACKEND_SERVER}/verifyAsset`,
//     data: data,
//     headers: { "Content-Type": "application/json; charset=utf-8" },
//   });
//   return response;

// };

const verifyAsset = async () => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_SERVER}/verifyAsset`,
    headers: { "Content-Type": "application/json; charset=utf-8" },
  });
  return response;
};

const AssetsDataService = {
  getAssetsAll,
  getAssetsAllNotApproved,
  getAssets,
  saveAsset,
  approveAsset,
  declineAsset,
  updateMeta,
  userMint,
  getListedAssetsAll,
  verifyAsset,
  getTracksbyAssetId,
  getAssumptionsbyAssetId,
  getAssetByAssetName,
  solToUSD,
  createAssumptions,
  updateAssumptions,
  getAssetByAssetID,
  updateAsset,
  getTracksByID,
  getTotalSharesByID,
};

export default AssetsDataService;
