import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
// import 'swiper/css/pagination';
import { Pagination } from 'swiper'
import HomeCard from './shared/HomeCard';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import './components.css'

function CardCarousel({ data }: any) {



    return (
        <><Swiper
            slidesPerView={3}
            spaceBetween={15}

            loop={true}
            loopFillGroupWithBlank={true}
            breakpoints={{

                0: {
                    slidesPerView: 2,
                },
                600: {
                    slidesPerView: 3,
                },
                900: {
                    slidesPerView: 4,
                },
                1300: {
                    slidesPerView: 5,
                },

            }}
            modules={[Pagination]}
            className="mySwiper home-card-carousel"
            style={{ overflow: 'visible' }}
        >
            {data.filter(function (card: any) {
                return card.status === 1;
            }).map((card: any, index: number) => (

                <SwiperSlide key={index}>
                    <HomeCard {...card} />
                </SwiperSlide>

            ))}
        </Swiper>
        </>
    )
}

export default CardCarousel