const authHeaderWithMultipartForm = () => {
  const user = localStorage.getItem("persist:root");
  if (user != null) {
    const data = JSON.parse(JSON.parse(user).user);
    if (data.currentUser?.token) {
      return {
        Authorization: "Bearer " + data.currentUser.token,
        "Content-Type": "multipart/form-data",
      };
    } else {
      return { Authorization: "", "Content-Type": "multipart/form-data" };
    }
  }
};

const AuthHeaderService = {
  authHeaderWithMultipartForm,
};

export default AuthHeaderService;
