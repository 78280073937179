import React from "react";
import "./imageCard.css";
function CongratsCard({ nftImg, mintPrice, user_id, nft_no }: any) {
  return (
    <div className="congrats-card">
      <div
        className="congrats-card-img"
        style={{
          // background: `url(${data.image}) center no-repeat`,
          background: `url(${nftImg?.image}) center no-repeat`,
          backgroundSize: "cover",
          zIndex: 30,
          // borderRadius: '10px'
        }}
      ></div>

      <p className="congrats-card-title">{nftImg?.name}</p>
      <p className="congrats-card-title">
        - <span className="congrats-hash">{nftImg?.author}</span> -
      </p>

      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <button className="rare-tag">${nftImg?.valuation}</button>
      </div>

      {/* <p className='congrats-card-sol'> </p> */}
    </div>
  );
}

export default CongratsCard;
