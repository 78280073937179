import React, { useState, useRef, useEffect } from 'react'
import './audioPlayer.css'
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import { VolumeDown } from '@material-ui/icons';
import { useSnackbar } from 'notistack'
import { AiOutlinePlayCircle } from "react-icons/ai";
import { BsFillStopFill, BsPlayFill } from "react-icons/bs";

function AudioModule(props: any) {

    //state 
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    // const [isPlaying, setIsPlaying] = useState(false);
    // const [isMute, setIsMute] = useState(false);
    // const [duration, setDuration] = useState<any>(0)
    // const [currentTime, setCurrentTime] = useState<any>(0)

    const [audio, setAudio] = useState(props.data)
    // const [audio, setAudio] = useState('http://qthttp.apple.com.edgesuite.net/1010qwoeiuryfg/sl.m3u8')
    // const [audio, setAudio] = useState('http://commondatastorage.googleapis.com/codeskulptor-demos/riceracer_assets/music/win.ogg')



    // const audio = 'https://commondatastorage.googleapis.com/codeskulptor-demos/riceracer_assets/music/lose.ogg'

    // alert


    // const audioPlayer: any = useRef(); // references audio
    // const progressbar: any = useRef(); // references progressbar
    // const animationRef: any = useRef(); // animation ref


    // useEffect(() => {
    //     const seconds: any = Math.floor(audioPlayer.current.duration)
    //     setDuration(seconds);
    //     progressbar.current.max = seconds;
    // }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState])

    // const calculateTime = (secs: any) => {
    //     const minutes = Math.floor(secs / 60);
    //     const returnMinutes = minutes < 10 ? `0${minutes}` : minutes

    //     const seconds = Math.floor(secs % 60);
    //     const returnSeconds = seconds < 10 ? `0${seconds}` : seconds

    //     return `${returnMinutes}:${returnSeconds}`
    // }



    // // play condition
    // useEffect(() => {
    //     if (isPlaying) {
    //         audioPlayer.current?.play()
    //         animationRef.current = requestAnimationFrame(whilePlaying)
    //         // return <AlertModule message='asd' type/>

    //     }
    //     else {
    //         audioPlayer.current?.pause()
    //         cancelAnimationFrame(animationRef.current)
    //     }
    // }, [isPlaying])



    // // mute condition
    // useEffect(() => {
    //     if (isMute && !!audioPlayer.current) {
    //         audioPlayer.current.muted = true;
    //     }
    //     else {
    //         audioPlayer.current.muted = false;
    //     }
    // }, [isMute])

    // // while playing
    // const whilePlaying = () => {
    //     progressbar.current.value = audioPlayer.current.currentTime;
    //     changePlayerCurrentTime();
    //     animationRef.current = requestAnimationFrame(whilePlaying)

    // }

    // // change progress bar
    // const changeRange = (event: any) => {
    //     event.stopPropagation()
    //     audioPlayer.current.currentTime = progressbar.current.value;
    //     changePlayerCurrentTime();

    // }

    // // change current time
    // const changePlayerCurrentTime = () => {
    //     progressbar.current.style.setProperty('--seek-before-width', `${progressbar.current.value / duration * 100}%`);
    //     setCurrentTime(progressbar.current.value)
    // }
    // // control play
    // const handlePlaying = (event: any) => {
    //     event.stopPropagation()

    //     setIsPlaying((preValue) => !preValue)
    // }
    // // control mute
    // const handleMute = (event: any) => {
    //     setIsMute((preValue) => !preValue)

    //     event.stopPropagation()
    // }

    // //    lock parent div on navigate 

    const [play, setPlay] = useState(false);

    const handleAudioModule = (event: any) => {
        if (audio === null) {
            enqueueSnackbar('Audio is not available for now', { variant: 'warning' })
        }
        event.stopPropagation();

    }

    return (
        <div className='audio-player' onClick={handleAudioModule}>

            <div className="audio-player-body">



                {/* <iframe src={audio} title="music" style={{ width: '100%', height: '100px' }}></iframe> */}
                {play ? (
                    <>
                        <BsFillStopFill className='audio-stop-ic' onClick={() => setPlay(false)} />
                        <iframe src={props.data} allow='autoplay' title="music" style={{ width: '0px', height: '0px' }}></iframe>

                    </>
                ) : (
                    <>
                        <BsPlayFill className='audio-play-ic' onClick={() => setPlay(true)} />

                    </>
                )}



                {/* <audio ref={audioPlayer} src={audio} preload='metadata'></audio> */}
                {/* <audio ref={audioPlayer} preload='metadata'>
                    <source src={audio} />
                </audio> */}

                {/* <div className='audio-player-top-view'>
                    <div className='audio-player-info'>
                        <button onClick={handlePlaying} className='play-pause'>
                            {isPlaying ? <FaPause className='pause-audio' /> : <FaPlay className='play-audio' />}
                        </button>
                        
                        <div className='audio-time-current'>{calculateTime(currentTime)}</div>
                        <div className='audio-time-current'> / </div>
                        
                        <div className='audio-time-duration'>{duration && !isNaN(duration) && calculateTime(duration)}</div>

                    </div>
                    <button onClick={handleMute} className='play-pause'>
                        {isMute ? <FaVolumeMute className='mute-audio mute-btn' /> : <FaVolumeUp className='mute-btn' />}
                    </button>
                </div>
                <div>
                    
                    <input type='range' className='audio-progressbar' defaultValue='0' ref={progressbar} onChange={changeRange} />
                </div> */}

            </div>
        </div>
    )
}

export default AudioModule